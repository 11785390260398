import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appVisibilityCheck]',
  standalone: true
})
export class VisibilityCheckDirective implements OnInit, OnDestroy {

  @Input() threshold: number = 0.1;

  @Output() visibilityChange = new EventEmitter<boolean>();

  private observer: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.visibilityChange.emit(entry.isIntersecting);
      });
    }, { threshold: this.threshold });

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

}
