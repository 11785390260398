<div class="info-box  mt-1" [ngClass]="[type == 'incoming'? 'incoming' : type == 'accepted'? 'accepted' : 'hold']">
    <div class="flex items-center">
        <div class="h-8 w-8 rounded-full mr-1 p-1 flex items-center justify-center text-lg bg-white border-black text-[#0E2445]">
           
            @if(callerSession?.customValues?.callType == 'inbound'){
                @if(callerSession?.customValues?.isAgentCall)
                {
                    @if(isNullorEmpty(callerSession?.callDetails?.agent_name)){
                        UN
                    }
                    @else{
                        {{callerSession?.callDetails?.agent_name | shortName}}
                    }
                }
                @if(!callerSession?.customValues?.isAgentCall)
                {
                    @if(isNullorEmpty(callerSession.callDetails?.tenant_customer_contact_name)){
                        UN
                    }
                    @else{
                        {{callerSession.callDetails?.tenant_customer_contact_name | shortName}}
                    }
                } 
            }
            @else if(callerSession?.customValues?.callType == 'outbound' && (callerSession?.session?.state == 'Established' || callerSession?.session?.state == 'Terminated'))
            {
                @if(callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession.callDetails?.to_sip_user?.name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.to_sip_user?.name | shortName}}
                            }
                        }
                        @if(!callerSession?.customValues?.isAgentCall)
                        {
                            @if(isNullorEmpty(callerSession.callDetails?.contact_name)){
                                UN
                            }
                            @else{
                                {{callerSession.callDetails?.contact_name | shortName}}
                            }
                        }
            }
        </div>
        <div class="flex flex-col ml-2">
            @if(callerSession?.customValues?.isAgentCall)
            {
                <div class="flex flex-col" *ngIf="callerSession?.customValues?.callType == 'inbound'">
                    <label class="flex text-lg font-semibold"><span class="max-w-[142px] truncate">{{isNullorEmpty(callerSession?.callDetails?.agent_name)? 'Unknown': callerSession?.callDetails?.agent_name+'('+callerSession.callDetails.agent_sub_org_name+')'}}</span><span *ngIf="callerSession?.customValues.isCallOnHold == true" class=""> - {{formatTime(callerSession?.customValues.elapsedTime)}}</span></label>
                    <label class="text-lg font-semibold">{{callerSession?.callDetails?.from_number.split('@')[0]}}</label>  
                </div>
                <div class="flex flex-col" *ngIf="callerSession?.customValues?.callType == 'outbound'">
                    <label class="flex text-lg font-semibold" *ngIf="(callerSession.session.state == 'Established' || callerSession.session.state == 'Terminated')">
                        <span class="max-w-[142px] truncate">{{isNullorEmpty(callerSession?.callDetails?.to_sip_user?.name)?'Unknown': callerSession?.callDetails?.to_sip_user?.name}}</span>
                    <span *ngIf="callerSession?.customValues.isCallOnHold == true" 
                    class=""> - {{formatTime(callerSession?.customValues.elapsedTime)}}</span></label>
                    <label class="text-lg font-semibold">{{callerSession?.callDetails.customer_number}}</label>  
                </div>
            }
            @if(!callerSession?.customValues?.isAgentCall)
            {
                <div class="flex flex-col" *ngIf="callerSession?.customValues?.callType == 'inbound'">
                    <label class="flex text-lg font-semibold"><span class="max-w-[142px] truncate">{{isNullorEmpty(callerSession?.callDetails?.tenant_customer_contact_name)? 'Unknown': callerSession?.callDetails?.tenant_customer_contact_name}}</span><span *ngIf="callerSession?.customValues.isCallOnHold == true" class=""> - {{formatTime(callerSession?.customValues.elapsedTime)}}</span></label>
                    <label class="text-lg font-semibold">{{callerSession?.callDetails.from_number|phoneNumber}}</label>  
                </div>
                <div class="flex flex-col" *ngIf="callerSession?.customValues?.callType == 'outbound'">
                    <label class="flex text-lg font-semibold" *ngIf="(callerSession.session.state == 'Established' || callerSession.session.state == 'Terminated')"><span class="max-w-[142px] truncate">{{isNullorEmpty(callerSession?.callDetails?.contact_name)? 'Unknown': callerSession?.callDetails?.contact_name}}</span><span *ngIf="callerSession?.customValues.isCallOnHold == true" class=""> - {{formatTime(callerSession?.customValues.elapsedTime)}}</span></label>
                    <label class="text-lg font-semibold">{{callerSession?.callDetails.customer_number|phoneNumber}}</label>  
                </div>
            }
     
        </div>
            <div class="flex ml-auto items-center">


                <!-- <div class="icon-container callend cursor-pointer mr-2" 
                 *ngIf="callerSession?.session?.state == 'Initial' || callerSession?.session?.state == 'push'" 
                 (click) = "onRejectCall()">
                    <img  alt="Mute"  src="../../../../../assets/icons/call-center/call-end.svg" >
                </div> --> 

                <!-- <div class="icon-container callend cursor-pointer mr-2" 
                *ngIf="callerSession.customValues?.notification_established == false && callerSession?.customValues?.callType == 'inbound'" 
                (click) = "onRejectCall()"> 
                   <img  alt="Mute"  src="../../../../../assets/icons/call-center/call-end.svg" >
               </div> -->

               <div class="icon-container callend cursor-pointer mr-2" 
               *ngIf="((callerSession.session?.state == 'push' && callerSession?.customValues?.callType == 'inbound') ||
                (callerSession?.customValues?.callType == 'outbound' && (callerSession.session?.state == 'push' ||
                 callerSession.session?.state == 'Initial')))"
                (click) = "onRejectCall()"> 
                  <img  alt="Mute"  src="../../../../../assets/icons/call-center/call-end.svg" >
              </div>

                <div class="icon-container callend cursor-pointer mr-2" 
                *ngIf="(callerSession?.session?.state == 'Established' ||
                callerSession?.session?.state == 'Establishing' ||
                 callerSession?.customValues?.isCallOnHold == true)"
                (click) = "onEndCall()">
                    <img  alt="Mute"  src="../../../../../assets/icons/call-center/call-end.svg" >
                </div>
              

                <div class="icon-container unhold cursor-pointer mr-2"  *ngIf="this.callerSession?.customValues?.isCallOnHold == true"  
                  (click) = "onHoldCall()">
                  <img  alt="Mute"  src="../../../../../assets/icons/call-center/unhold-ico.svg" >
                </div>

                <div class="icon-container callpick cursor-pointer mr-2" (click)="onPickCall()"
                *ngIf="this.callerSession.customValues.callType == 'inbound' && this.callerSession?.session?.state != 'Established' && !this.callerSession?.customValues?.isCallOnHold
                 && this.callerSession?.session?.state !== 'Terminated' && this.callerSession?.session?.state != 'connect'">
                <img  alt="Mute"  src="../../../../../assets/icons/call-center/call-pick.svg" >
                </div>
                <div class="spinner mr-2"  *ngIf="this.callerSession?.session?.state == 'connect'">
                    <!-- <label>loading ...</label> -->
                    <div role="status">
                     <svg aria-hidden="true" class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 
                         22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 
                         73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921
                          72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                         <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932
                          28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422
                           4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
                           1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511
                            9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735
                             17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083
                              38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                     </svg>
                     <span class="sr-only">Loading...</span>
                 </div>
                 </div>

            </div>    
    </div>
    <div class="flex flex-col item-center cus-info">
        <div class="div">
            @if(callerSession?.customValues?.isAgentCall)
            {
                <label class="text-base font-medium">{{isNullorEmpty(callerSession?.callDetails?.to_sip_user?.sub_org__name)? '': callerSession?.callDetails?.to_sip_user?.sub_org__name}}</label>
            }
            @if(!callerSession?.customValues?.isAgentCall)
            {
                <label class="text-base font-medium">{{isNullorEmpty(callerSession?.callDetails?.tenant_customer_name)? 'Unknown': callerSession?.callDetails?.tenant_customer_name}}</label>
              
            }
        </div>
        <div class="div">
            <label class="text-base font-medium" *ngIf="callerSession?.customValues?.callType == 'inbound' && !callerSession?.customValues?.isAgentCall">Route Name: {{isNullorEmpty(callerSession?.callDetails?.incoming_route_name)? 'Unknown': callerSession?.callDetails?.incoming_route_name}}</label>
        </div>
    </div>

    
</div>
