import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AppConstants } from '../constants/app.constants';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> =>
{
    const _router = inject(Router);

    const accessToken = localStorage.getItem(AppConstants.AUTH_TOKEN);

    const suborg = localStorage.getItem(AppConstants.CURRENT_SUBORG);

    let customHeaders = {};

    if (accessToken) {
        customHeaders['Authorization'] = `Token ${accessToken}`;
    }

    if (suborg&&!req.headers.has('Suborg')) {
        customHeaders['Suborg'] = suborg;
    }

    req = req.clone({ setHeaders: customHeaders });

    return next(req).pipe(
        map((event: HttpEvent<any>) => {
            return event;
        }),
        catchError((httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
            if (httpErrorResponse.status === 401) {
                localStorage.clear();
                _router.navigate(['/sign-in']);
            }
            if (httpErrorResponse.error && httpErrorResponse.error.suborg && suborg === '-1') {
                // AllSuborgCreateAlert();
            }
            return throwError(() => HttpErrorResponse);
        })
    );
};
