import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  public baseURL: string = environment.apiURL;
  private currentVersionKey = 'appVersion';
  private apiUrl = `${this.baseURL}/app_min_versions`;
  private lastVersionCheckedKey = 'latestVersionCheckTime';

  private checkInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  private intervalId: any;
  constructor(private http: HttpClient,
    private _fuseConfirmDialogService: FuseConfirmationService,
  ) {}

  public setupVersionCheck(): void {
    this.initVersionCheck(); // Initial check
    setInterval(() => this.initVersionCheck(), this.checkInterval);
  }

  private initVersionCheck(): void {
    const lastCheck = localStorage.getItem(this.lastVersionCheckedKey);
    const now = new Date().getTime();

    // If last check was more than a day ago, perform the check
    if (!lastCheck || now - parseInt(lastCheck, 10) > 24 * 60 * 60 * 1000) {
      this.checkVersion().subscribe();
    }
  }

  private checkVersion(): Observable<any> {
    return this.http.get(this.apiUrl).pipe(
      tap((response: any) => {
        const latestVersion = response.data.result.versions.android_min_version;
        const currentVersion = localStorage.getItem(this.currentVersionKey);
        localStorage.setItem(this.lastVersionCheckedKey, new Date().getTime().toString());
        if(!currentVersion) { 
          localStorage.setItem(this.currentVersionKey, latestVersion);
          return;
        }
        this.handleVersionCheck(latestVersion);
        
      }),
      catchError((error) => {
        console.error('Error checking version', error);
        return of(null);
      })
    );
  }

  private handleVersionCheck(latestVersion: string): void {
    const currentVersion = localStorage.getItem(this.currentVersionKey);
    if (this.isNewVersionAvailable(currentVersion, latestVersion)) {
      this.openUpdateConfirmation(latestVersion);
    }
  }

  private isNewVersionAvailable(currentVersion: string, latestVersion: string): boolean {
    console.log('current version = ',currentVersion, 'latest version =', latestVersion);
    return currentVersion < latestVersion;
  }

  private openUpdateConfirmation(latestVersion): void {
    let config = this.getConfirmConfig();
    let dialogRef = this._fuseConfirmDialogService.open(config);
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'confirmed') {
        localStorage.setItem('showUpdateMessage', 'true');
        localStorage.setItem(this.currentVersionKey, latestVersion);
        window.location.reload();
      } 
    });
  }

  private getConfirmConfig():FuseConfirmationConfig{
    return  {
      title: 'New update available',
      message: 'A new version of the app is available. Please update to continue using the application.',
      icon: {
        show: true,
        name: 'mat_solid:update',
        color: 'primary'
      },
      actions: {
          confirm: {
              show: true,
              label: 'Update',
              color: 'primary'
          },
          cancel: {
              show: false
          }
      },
      dismissible: false,
    }
  }
}
