import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, filter } from "rxjs";
import { AppEvent } from "../models/common.models";
import { AppEventType } from "../enums/common.enum";

@Injectable({
    providedIn: 'root'
})
export class CommonService{
    
    private eventBrocker = new Subject<AppEvent<any>>()

    public title = new Subject<string>();
    
    constructor() { }

    on(eventType: AppEventType): Observable<AppEvent<any>> {
        return this.eventBrocker.pipe(filter(event => event.type === eventType));
    }
    
    dispatch<T>(event: AppEvent<T>): void {
        this.eventBrocker.next(event);
    }
    
    public setTitle(value){
        this.title.next(value);
    }

    public isSip(value: any): boolean{
        value = value.split("@")[0].replace(/\D/g, '');
        return value?.length <= 6 ? true : false;
    }
}