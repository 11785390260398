import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallNotificationService } from '../../services/call-notification.service';
import { CallCenterSharedService } from '../../services/call-center-shared.service';
import { InboundNotifyListinfoComponent } from '../inbound-notify-listinfo/inbound-notify-listinfo.component';
import { CallListInfoComponent } from '../call-list-info/call-list-info.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'call-list',
  standalone: true,
  imports: [CommonModule, InboundNotifyListinfoComponent,CallListInfoComponent,
    TranslocoModule
  ],
  templateUrl: './call-list.component.html',
  styleUrl: './call-list.component.scss',
  
})
export class CallListComponent {

  @Output() pickCall = new EventEmitter<any>

  @Output() rejectCall = new EventEmitter<any>

  @Output() endCall = new EventEmitter<any>

  @Output() holdCall = new EventEmitter<any>

  constructor(public callNotifyService : CallNotificationService,
    public callcenterSharedService: CallCenterSharedService,
  )
  {}

  onPickCall(session:any)
  {
    this.pickCall.emit(session)
  }

  onRejectCall(session:any)
  {
    this.rejectCall.emit(session)
  }

  onEndCall(session:any)
  {
    this.endCall.emit(session)
  }

  onHoldCall(session:any)
  {
    this.holdCall.emit(session)
  }
}
