import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Chat, ViewMessagePayload, Messages, SendMessagePayload, CampaignNumbers } from '../models/chats.models';
import { BaseService } from 'app/core/services/base.service';
import { DataFilterResponse } from 'app/core/models/grid-filter.models';
import { CustomerContact, RecentActivityResponse } from '../models/chat-contacts.models';
import { ChatBinPayload, ChatRouteBin } from '../models/sms-route-models';
import { Subject } from 'rxjs';
import { SfHttpService } from 'app/shared/services/sf-http.service';
import { error } from 'console';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {

  constructor(private _http: HttpClient, private sfHttpService: SfHttpService) { super(); }

  private notificationData: any;

  public toNumber: any;

  public store_Chat$ = new Subject<any>();

  public store_ChatMsg$ = new Subject<any>();

  public store_ChatContactDet$ = new Subject<any>();

  private storeChatName: string = 'chats';

  private storeChatMsgName: string = 'chat_msg';

  private storeChatContactDet: string = 'chat_contact_det'


  setNotificationData(data: any) {
    this.notificationData = data;
  }

  getNotificationData() {
    return this.notificationData;
  }

  clearNotificationData() {
    this.notificationData = null;
  }

  //* to get chat heads
  getChatList(request: any, offset: number = 0, limit: number = this.dataLimit) {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());
    return this._http.post<DataFilterResponse<Chat[]>>(`${this.baseURL}/chat/filter/`, request, { params });
  }

  getChatList_cache(request: any, offset: number = 0, limit: number = this.dataLimit,locDBFilter:any = {}) {
    
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());
     //return this._http.post<DataFilterResponse<Chat[]>>(`${this.baseURL}/chat/filter/`, request, { params });


    const apiCallurl = `${this.baseURL}/chat/filter/`;
    return this.sfHttpService.post<Chat>(apiCallurl,request,this.storeChatName,limit,offset,this.store_Chat$,params,50,'contact_details',locDBFilter).subscribe(res=>{}
      ,error=>{
        console.log('error',error);
      }
    );
  }

  // //*to get messages corresponding to contact
  // getChatMessages(payload: ViewMessagePayload, offset: number = 0, limit: number = this.dataLimit) {
  //   const params = new HttpParams()
  //     .set('limit', limit.toString())
  //     .set('offset', offset.toString());
  //   return this._http.post<DataFilterResponse<Messages[]>>(`${this.baseURL}/view-chat/`, payload, { params });
  // }

  //*to get messages corresponding to contact
  getChatMessages_cache(payload: ViewMessagePayload, offset: number = 0, limit: number = this.dataLimit) {

    const params = new HttpParams()
    .set('limit', limit.toString())
    .set('offset', offset.toString());
    const apiCallurl = `${this.baseURL}/view-chat/`;
    return this.sfHttpService.post<Chat>(apiCallurl,payload,this.storeChatMsgName,limit,offset,
      this.store_ChatMsg$,params,50).subscribe(res=>{}
      ,error=>{
        console.log('error',error);
    });
  }

  // * send a message
  sendMessage(payload: SendMessagePayload) {
    return this._http.post<DataFilterResponse<Messages>>(`${this.baseURL}/send-message/`, payload);
  }

  // * mark message as read
  markAsRead(customer_number: string, signalwire_number: string, suborg_id: number) {
    let headers = new HttpHeaders({
      'Suborg': suborg_id
    });
    let payload = { customer_number: customer_number, signalwire_number: signalwire_number }
    return this._http.post<{ message: string }>(`${this.baseURL}/mark-as-read/`, payload, { headers: headers });
  }

  // * mark as unread
  markAsUnread(customer_number: string, signalwire_number: string, message_route: number, suborg_id: number) {
    let headers = new HttpHeaders({
      'Suborg': suborg_id
    });
    let payload = { customer_number: customer_number, signalwire_number: signalwire_number, message_route: message_route }
    return this._http.post<{ message: string }>(`${this.baseURL}/mark-as-unread/`, payload, { headers: headers });
  }


  //* get customer contact details
  // getContactDetail(id: number) {
  //   return this._http.get<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/${id}/`);
  // }

  getContactDetail(id: number) {

    let url = `${this.baseURL}/tenant_customer/contacts/${id}/`;
   // return this._http.get<DataFilterResponse<CustomerContact>>(`${this.baseURL}/tenant_customer/contacts/${id}/`);
   this.sfHttpService.getbyID(url,this.storeChatContactDet,this.store_ChatContactDet$,id).subscribe((res)=>{

   })
  }
  // * Recent activity of contact
  getRecentActivity(id: number) {
    return this._http.get<DataFilterResponse<RecentActivityResponse[]>>(`${this.baseURL}/recent_activity/${id}`);
  }

  //* list campaign numbers
  getAllCampaignNumbers() {
    return this._http.get<DataFilterResponse<CampaignNumbers[]>>(`${this.baseURL}/list-campaign/`);
  }

  // chat bin apis
  getAllChatBin(request: any, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/message_route/filter/?limit=${limit}&offset=${offset}`;
    return this._http.post<DataFilterResponse<ChatRouteBin[]>>(url, request);
  }

  getChatBinById(id: any) {
    return this._http.get<DataFilterResponse<ChatRouteBin>>(`${this.baseURL}/message_route/${id}`);
  }

  createChatBin(model: ChatBinPayload) {
    return this._http.post<DataFilterResponse<ChatBinPayload>>(`${this.baseURL}/message_route/`, model);
  }

  updateChatBin(id: string, model: ChatBinPayload) {
    return this._http.put<DataFilterResponse<ChatBinPayload>>(`${this.baseURL}/message_route/${id}`, model);
  }

  deleteChatBin(id: number) {
    return this._http.delete<DataFilterResponse<any>>(`${this.baseURL}/message_route/${id}`);
  }

}
