<section id="inbound-call-listing fadeindown" *transloco="let t;scope:'callcenter'" >
    <div class="mt-3" *ngIf="
        this.callcenterSharedService.initialCallQ?.length>0">
        <label class="call-list-title">{{t('callcenter.CallList')}}</label>
        <div class="mt-1">
            <!-- <div *ngIf="(this.callNotifyService.callNotificationData$ | async)?.call_id?.length>0">
                <inbound-notify-listinfo [call_Details] = "this.callNotifyService.callNotificationData$ |async"></inbound-notify-listinfo>
            </div> --> 
            <div #incomingCallsList> 
            <call-list-info class="mt-1" [type] = "'incoming'" *ngFor="let callsession of this.callcenterSharedService.initialCallQ"
                [callerSession]="callsession" (pickCall) = "onPickCall($event)"  (rejectCall) = "onRejectCall($event)" (holdCall) = "onHoldCall($event)"
                (endCall) = "onEndCall($event)">
            </call-list-info>
            </div>        
        </div>
    </div>

    <div class="mt-1" *ngIf="this.callcenterSharedService.holdCallQ?.length>0">
        <label class="call-list-title ">{{t('callcenter.OnHoldCalls')}}</label>
        <div class="mt-1">
            <div #holdCallsList>   
            <call-list-info class="mt-1" [type] = "'hold'" *ngFor="let callsession of this.callcenterSharedService.holdCallQ"
                [callerSession]="callsession" (pickCall) = "onPickCall($event)"  (rejectCall) = "onRejectCall($event)" (holdCall) = "onHoldCall($event)"
                (endCall) = "onEndCall($event)"></call-list-info>
            </div>        
        </div>
    </div>
</section>