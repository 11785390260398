import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'key-pad',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './key-pad.component.html',
  styleUrl: './key-pad.component.scss'
})
export class KeyPadComponent {

  @Input() session : any = {}

  // public keyInputs: string = ''

  @Output() closeKeypad: EventEmitter<boolean>  = new EventEmitter<boolean>();

  constructor(  ){}

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {

  
    const allowedKeys = ['(',')','0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (!allowedKeys.includes(event.key)) {
      return
    }
    if(!this.session.customValues.keyInputs)
      {
        this.session.customValues.keyInputs = ''
      }
    this.session.customValues.keyInputs += event.key
  }

  onKeyPress(val: string) {
   
    this.session.customValues.keyInputs = this.session.customValues.keyInputs?.length? this.session.customValues.keyInputs += val : val
    this.sendKey(val)

  }

  sendKey(key) 
  {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: "Signal="+key+"\r\nDuration=1000"
        }
      }
    };
    console.log("this.session",this.session);
    
    this.session.session.info(options)
  }

  onCloseKeypad()
  {
    this.closeKeypad.emit(true)
  }



}
