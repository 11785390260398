<div class="fade-in">
    <div class="dialler-input-div">
        <span (click) = "onCloseKeypad()">
            <i class="icon-arrow-left text-2xl cursor-pointer"></i>
        </span>         
        <input type="text" class="form-control"  placeholder="Enter number here"  
        [(ngModel)]="session.customValues.keyInputs" readonly maxlength="15" />
    
    
    </div>
    
    <div class="container  flex justify-center items-center">
        <div class="grid grid-cols-3 gap-x-6 gap-y-2.5 mt-2 mb-2">
            <button class="key bg-white rounded-full shadow-md flex items-center justify-center" (click) = "onKeyPress('1')">1</button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('2')">
                    <span  class="num">2</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('3')">
                    <span class="num">3</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('4')">
                <span class="num">4</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('5')">
                <span class="num">5</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('6')">
                <span class="num">6</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('7')">
                <span class="num">7</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('8')">
                <span class="num">8</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('9')">
                <span class="num">9</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center justify-center" (click) = "onKeyPress('*')">
                <span class="num">*</span>
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center flex-col justify-center" (click) = "onKeyPress('0')">
                <span class="num">0</span>
                <!-- <span class="char">+</span> -->
            </button>
            <button class="key bg-white rounded-full shadow-md flex items-center  justify-center" (click) = "onKeyPress('#')">
                <span class="num">#</span>
            </button>
    
    
            <!-- <div class="mt-4"></div> -->
    
    
        </div>
    </div>
</div>

