import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallsDashBoardData, DIDNumber, DIDNumberReport, DIDRequest } from '../models/dids.models';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CallLog } from '../models/reports.models';
import { BaseService } from 'app/core/services/base.service';
import { DataFilterGroupRequest, DataFilterRequest, DataFilterResponse } from 'app/core/models/grid-filter.models';

@Injectable({
    providedIn: 'root'
})
export class DidsService extends BaseService {

    public currentStep: number = 1;

    constructor(private http: HttpClient) { super(); }

    getAll(request: any, offset: number = 0, limit: number = this.dataLimit, suborg: number = this.suborgId) {
        let headers = new HttpHeaders().set("Suborg", suborg.toString());
        let url = `${this.baseURL}/did/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<DIDNumber[]>>(url, request, { headers: headers });
    }

    exportDidReport(request: DataFilterRequest) {
        return this.http.post(`${this.baseURL}/did/filter/?export=true`, request, { responseType: 'blob' });
    }

    getCallReport(request: any, offset: number = 0,isAllSubOrg:boolean=false,  limit: number = this.dataLimit, hideLoading:boolean=false) {

        let headers=null;
        // if(hideLoading){
        //     headers = new HttpHeaders({
        //         'Hideloading': 'true',
        //       });
        // }

        let url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}`;;
        // if(isAllSubOrg)
        // {   
        //     url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}&suborg=${-1}`;
        // }
        // else
        // {
        //     url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}`;
        // }


        // let url = `${this.baseURL}/calls/filter/?limit=${limit}&offset=${offset}`;
        return this.http.post<DataFilterResponse<CallLog[]>>(url, request, { headers: headers }).pipe(
            catchError(() => of({data:{ count: 0, result: [], total_count: 0 }}))
        );
    }

    callReportExport(request: DataFilterGroupRequest,count:number=0) {
        return this.http.post(`${this.baseURL}/calls/filter/?export=true&export_count=${count}`, request, { responseType: 'blob' });
    }

    getCallHistory(request: any, offset: number = 0, limit: number = this.dataLimit) {
        let _headers = new HttpHeaders().set("Suborg", '-1');
        let _params = new HttpParams().set("limit", limit).set("offset", offset);
        let url = `${this.baseURL}/calls/history/`;
        return this.http.post<DataFilterResponse<CallLog[]>>(url, request, {headers: _headers, params: _params}).pipe(
            catchError(() => of({data:{ count: 0, result: [], total_count: 0 }}))
        );
    }

    getCallHistoryDashboard(){
        let url = `${this.baseURL}/calls/dashboard/`;
        return this.http.get<any>(url);
    }

    getPhonenumbersDashboard(){
        let url = `${this.baseURL}/did/dashboard/`;
        return this.http.get<any>(url);
    }

    bulkDelete(request: { phone_numbers: string[], suborg_id: number }) {
        let url = `${this.baseURL}/did/bulk_delete/`;
        return this.http.post<DataFilterResponse<any>>(url, request);
    }
        
}