import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { InitiateCallDirective } from 'app/shared/directives/initiate-call.directive';
import { AppConstants } from 'app/core/constants/app.constants';
import { PhoneNumberPipe } from 'app/shared/pipes/phone-number.pipe';
import { InitiateChatDirective } from 'app/shared/directives/initiate-chat-direcive';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { environment } from 'environments/environment';
import { CommonService } from 'app/core/services/common.service';

export class ContactInfoCard{
  name: string;
  image: string;
  email: string;
  number: string;
} 

@Component({
  selector: 'app-contact-info-card',
  standalone: true,
  imports: [CommonModule, ShortNamePipe, InitiateCallDirective, PhoneNumberPipe, InitiateChatDirective],
  templateUrl: './contact-info-card.component.html',
  styleUrl: './contact-info-card.component.scss'
})

export class ContactInfoCardComponent {
  @Input() data: ContactInfoCard;
  @Input() showMoreInfo: boolean = true;
  @Output() action = new EventEmitter<string>();
  @Output() mouseEnter = new EventEmitter<void>();
  @Output() mouseLeave = new EventEmitter<void>();

  public agentSipUri: string;

  fileUrl: string = environment.imgURL;

  constructor(public callcenterShared: CallCenterSharedService,
    public _commonService: CommonService
  ){
    this.agentSipUri = localStorage.getItem(AppConstants.SIP_URI)
  }

  onAction(action: string) {
    this.action.emit(action);
    // if(action=='chat'){
    //     const isOpen = this.callcenterShared.popupOpenStatus.getValue()
    //     this.callcenterShared.showDialer = !isOpen
    //     this.callcenterShared.popupOpenStatus.next(!isOpen)
    // }
  }

  onMouseEnter() {
    this.mouseEnter.emit();
  }

  onMouseLeave() {
    this.mouseLeave.emit();
  }

  isSip(value){
    return this._commonService.isSip(value)
  }
}
