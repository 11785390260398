import { Routes } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';

export const EXT_LAYOUT_ROUTES: Routes = [
    {
        path: '',
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('../../modules/auth/auth.routes').then(c=>c.default)
    }
];