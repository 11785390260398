import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { CallCenterSharedService } from 'app/modules/ring2voice/services/call-center-shared.service';
import { SipOutboundFunctionService } from 'app/modules/ring2voice/services/sip-outbound-function.service';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[InitiateCall]',
  standalone: true
})
export class InitiateCallDirective {

/**
   * Directive to initiate a call.
   * 
   * When applied to an element, this directive listens for click events and triggers
   * the initiation of a call using the provided parameters: callType, fromNumber, toNumber, agentsipuri, and callto_agent_id.
   * 
   * Example usage:
   * <button InitiateCall
   *         [callType]="'contact'"
   *         [fromNumber]="callerPhoneNumber"
   *         [toNumber]="recipientPhoneNumber"
   *         [agentsipuri]="sipUri">
   *   Initiate Call
   * </button>
   */

  @Input({required:true}) callType: 'contact'|'sip';

  @Input() fromNumber: any;

  @Input({required:true}) toNumber: any;

  @Input({required:true}) agentsipuri: string;
  
  @Input() callto_agent_id?: string;

  @Input() row: any;

  private tooltip: HTMLElement;
  isHovered: boolean;

  constructor(private callCenterService: SipOutboundFunctionService,
    private callcenterShared: CallCenterSharedService,
    private toastr : ToastrService,
    private el: ElementRef, private renderer: Renderer2
  ) { }

  /**
   * @Input callType: The type of call to initiate (e.g., 'contact' or 'sip').
   * @Input fromNumber: The source of the call.
   * @Input toNumber: The destination of the call.
   * @Input agentsipuri: The SIP URI of the agent initiating the call.
   * @Input callto_agent_id (optional): The ID of the agent to call (if callType=='sip).
   */

  @HostListener('click')
  onClick() {
    if(this.callcenterShared.activeCallerSession){
      this.toastr.error("You are already in a call...")
      return;
    }  
    console.log('type:',this.callType, 'from:',this.fromNumber, 'to:',this.toNumber, 'sipuri:',this.agentsipuri, 'callto_agentid:',this.callto_agent_id);
    // Initiate the call using the Call Center Service
    this.callCenterService.makeCall(this.callType, this.callcenterShared.sipDetails.send_as, this.toNumber, this.agentsipuri, this.callto_agent_id);
    this.callcenterShared.popupOpenStatus.next(true)
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    this.isHovered = true;
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHovered = false;
    this.hideTooltip();
  }

  private showTooltip() {
    this.tooltip = this.renderer.createElement('span');
    const text = this.renderer.createText(this.getTooltipText());
    this.renderer.appendChild(this.tooltip, text);
    this.renderer.appendChild(document.body, this.tooltip);
    this.renderer.addClass(this.tooltip, "custom-tooltip");
    this.renderer.setStyle(this.tooltip, 'position', 'fixed');
    this.renderer.setStyle(this.tooltip, 'background', '#333');
    this.renderer.setStyle(this.tooltip, 'color', '#fff');
    this.renderer.setStyle(this.tooltip, 'padding', '5px');
    this.renderer.setStyle(this.tooltip, 'borderRadius', '5px');
    this.renderer.setStyle(this.tooltip, 'z-index', '1000');
    this.renderer.setStyle(this.tooltip, 'white-space', 'nowrap');

    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();

    let top = hostPos.top - tooltipPos.height - 5;
    let left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;

     // Ensure tooltip is within the viewport
     top = Math.max(top, 0); // Prevent moving above the viewport
     // Ensure tooltip does not overflow the right edge of the viewport
     if (left + tooltipPos.width > window.innerWidth-20) {
       left = window.innerWidth - tooltipPos.width - 20; // Adjust to be within the viewport
     }

     // Ensure tooltip does not overflow the left edge of the viewport
     if (left < 0) {
       left = 10; // Adjust to be within the viewport
     }

     this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
     this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  private hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }

  getTooltipText(){
      let tooltip = ["Call"]
      if(this.row?.direction == 'inbound'){
        if(this.row?.customer_contact_name?.trim())
          tooltip.push(this.row?.customer_contact_name.trim())
        else if(this.row?.created_by_name?.trim())
          tooltip.push(this.row?.created_by_name.trim())
        else
          tooltip.push(this.toNumber)
      }
      else if(this.row?.direction == 'outbound'){
        if(this.row?.accepted_by_name?.trim())
          tooltip.push(this.row?.accepted_by_name.trim())
        else if(this.row?.call_to_name?.trim())
          tooltip.push(this.row?.call_to_name.trim())
        else
          tooltip.push(this.toNumber)
      }
      else
        tooltip.push(this.toNumber)
      return tooltip.join(" ")
  }

  show(){
    const tooltips = document.querySelectorAll('.custom-tooltip'); // Select all tooltips with the custom class
    if(this.isHovered){
      tooltips.forEach((tooltip) => {
        this.renderer.setStyle(tooltip, 'display', 'block');
      });
    }
    // else{
    //   tooltips.forEach((tooltip) => {
    //     tooltip.remove();
    //   });
    // }
  }
  hide(){
    const tooltips = document.querySelectorAll('.custom-tooltip'); // Select all tooltips with the custom class

    tooltips.forEach((tooltip) => {
      this.renderer.setStyle(tooltip, 'display', 'none');
    });
  }

}
