export { }

declare global {
    interface String {
        format(...args: any[]): string;
    }
}

String.prototype.format = function (...args) {
    let a = this;
    for (let k in args) {
        a = a.replace(`{${k}}`, args[k]);
    }
    return a
}

export function id() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function ObjaryToSa(arry:any,key:any){

    let newArry=[];
    arry.map((tab:any, index:any) =>{
        // var phoneStr = tab[key].replaceAll("[()\\s-]+", "");

        // console.log((phoneStr));
      newArry.push((tab[key]));
      newArry.push((tab[key]))
    });
    return newArry;

}

export function ObjaryTosas(arry:any,key:any){

    let newArry=[];
    arry.map((tab:any, index:any) =>{
      newArry.push((tab[key]))
    });
    return newArry;

}

export function getRandomLightColor() {
    var letters = 'BCDEF'.split('');
                var color = '#';
                for (var i = 0; i < 6; i++ ) {
                    color += letters[Math.floor(Math.random() * letters.length)];
                }
                return color;
}

export function getLightColor(level:number) {
    return ['','#a4eca46e', '#add8e685', '#FFB6C1', '#FFFFE0', '#D3D3D3'][level]
}


