import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber',
  standalone: true
})
export class PhoneNumberPipe implements PipeTransform {

  constructor() { }

  transform(value: any, showCountryCode: boolean = true): any {
    if (!value) return "";
    value = value.split("@")[0].replace(/\D/g, '');
    //Phone number max length is 15
    if(value?.length > 15)
      value = value.slice(0, 15);

    // Format the phone number using AsYouType with country code 'US'
    let formattedNumber = new AsYouType('US').input(value);

    // If showCountryCode is false and number starts with "1 ", remove it
    if (!showCountryCode && formattedNumber.startsWith("1 ")) {
      formattedNumber = formattedNumber.substring(2); // Removes the "1 " from the start
    }

    return value?.length > 6 ? formattedNumber : value;
  }

}
