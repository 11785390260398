import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilterRequest, DataFilterResponse } from 'app/core/models/grid-filter.models';
import { BaseService } from 'app/core/services/base.service';
import { SIP } from '../models/sip.models';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SipService extends BaseService {

  public assignSiptoUser: string;

  constructor(private http: HttpClient) { super();}

  get(request: DataFilterRequest, offset: number = 0, limit: number = this.dataLimit) {
    let url = `${this.baseURL}/sip/data/filter/?limit=${limit}&offset=${offset}`;
    return this.http.post<DataFilterResponse<SIP[]>>(url, request);
}

getById(sid: string) {
    const request: DataFilterRequest = {
        base_filters: [{ colname: 'id', condition: 'is', value: sid, operator: 'AND' }],
        filters: null,
        sort: null
    }
    return this.get(request, 0, 1).pipe(map(resp => {
        if (resp.data?.result?.length) {
            let obj = resp.data?.result[0];
            obj.ciphers = obj.ciphers ? obj.ciphers.split(',') : [];
            obj.codecs = obj.codecs ? obj.codecs.split(','): [];
            return obj;
        }
        return null;
    }));
}

create(model: SIP) {
    return this.http.post<SIP>(`${this.baseURL}/sip/`, model);
}

update(id: string, model: SIP) {
    return this.http.put<SIP>(`${this.baseURL}/sip/${id}/`, model);
}

updateSendAs(id: string, model: SIP) {
    return this.http.patch<SIP>(`${this.baseURL}/sip/${id}/`, model);
}

delete(sid: string) {
    return this.http.delete(`${this.baseURL}/sip/${sid}/`);
}

sendSIPVerificationCode(id:string) {
    return this.http.get(`${this.baseURL}/sip/${id}/send_verification_code/`);
}

verifySIPVerificationCode(id:string, otp:string) {
    return this.http.post(`${this.baseURL}/sip/${id}/verify_code/`, {otp: otp});
}
}
