import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const _router: Router = inject(Router);
    const _authservice: AuthService = inject(AuthService);

    // Check the authentication status
    if (!_authservice.isLoggedIn) {
        _router.navigate(['sign-in']);
        return false;
    }
    return true;
};
