import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavRoutes } from 'app/core/routes/nav.routes';
import { CallCenterPanelComponent } from 'app/modules/ring2voice/components/call-center-panel/call-center-panel.component';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { Location } from '@angular/common'
import {MatSelectModule} from '@angular/material/select';
import { UserTypes } from 'app/core/enums/user-types';
import { SubOrg } from 'app/modules/suborg/models/suborg.model';
import { AppConstants } from 'app/core/constants/app.constants';
import { FormsModule } from '@angular/forms';
import { AppEvent } from 'app/core/models/common.models';
import { AppEventType } from 'app/core/enums/common.enum';
import { ShortNamePipe } from 'app/shared/pipes/short-name.pipe';
import { MatRippleModule } from '@angular/material/core';
import { ActiveCallBadgeComponent } from 'app/modules/ring2voice/components/active-call-badge/active-call-badge.component';
import { CommonService } from 'app/core/services/common.service';
@Component({
    selector     : 'classic-layout',
    templateUrl  : './classic.component.html',
    styleUrls    : ['./classic.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [FuseLoadingBarComponent, FuseVerticalNavigationComponent, MatButtonModule,
         MatIconModule, LanguagesComponent, SearchComponent, ShortcutsComponent,
          MessagesComponent, NotificationsComponent, UserComponent, NgIf, NgFor, FormsModule, RouterOutlet, 
          CallCenterPanelComponent, MatSelectModule, ShortNamePipe, MatRippleModule,
          ActiveCallBadgeComponent],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];


    public UserTypeEnum = UserTypes;

    public userType: UserTypes;
    
    public suborgs: SubOrg[];

    public suborgSelected: SubOrg;

    public total_count:number;
    
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public tempName:string="Bentley Ericson";

    public title: any;
    notificationsEnabled: boolean = false;
    /**
     * Constructor
     */
    constructor(

        private _router: Router,
        private location: Location,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private firemsgService : FireMessagingService,
        private commonService: CommonService,
        private cdr: ChangeDetectorRef
    )
    {
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.firemsgService.pushNotification.subscribe(val=>{

            if(val?.data)
            {
                
            }
        })
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) =>
        //     {
        //         this.navigation = navigation;
        //     });

        this.initSuborgDropdown();

        this.navigation=NavRoutes;

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('lg');
            });

            this.firemsgService.requestPermission()
            // this.firemsgService.listen()//call in service
        this.commonService.title.subscribe((value)=> 
        {
            this.title = value;
            this.cdr.detectChanges()
        });

   
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
    initSuborgDropdown() {

        if (this.userType !== this.UserTypeEnum.SuperUser) {

            this.suborgs = JSON.parse(localStorage.getItem(AppConstants.USER_SUBORGS)) || [];

            const current = +localStorage.getItem(AppConstants.CURRENT_SUBORG);

            if (this.suborgs.length) {

                this.suborgSelected = this.suborgs.find(item => item.id == current);
                this.total_count = this.suborgs.length
            }
        }
    }

    setSuborg(suborg: any) {
        // if (suborg.id === this.suborgSelected?.id) return;
        // this.suborgSelected = suborg;
        localStorage.setItem(AppConstants.CURRENT_SUBORG, suborg.value.id.toString());
        this.commonService.dispatch(new AppEvent(AppEventType.SuborgSwitch, suborg));
    }

    

}
