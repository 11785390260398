import { Route } from '@angular/router';
// import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EXT_LAYOUT_ROUTES } from './core/routes/ext.routes';
import { INNER_LAYOUT_ROUTES } from './core/routes/inner.routes';
import { Error404Component } from './shared/components/error/error-404/error-404.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        data: {
            layout: 'empty'
        },
        children: EXT_LAYOUT_ROUTES,
    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver
        // },
        data: {
            layout: 'classic'
        },
        children: INNER_LAYOUT_ROUTES,
    },
    {
        path: '**',
        component: Error404Component
    }
];
