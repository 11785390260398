// Encode data with a string token using btoa
export function encodeDataWithToken(data: any, token: string): string {
  const stringifiedData = JSON.stringify(data);
  const dataWithToken = token + stringifiedData; // Add token to data
  return btoa(dataWithToken);  // Encode the data and token to Base64
}

// Decode data with a string token using atob
export function decodeDataWithToken(encodedString: string, token: string): any {
  const decodedString = atob(encodedString); // Decode the Base64 string
  if (decodedString.startsWith(token)) {
    // Remove the token from the decoded data
    const originalData = decodedString.slice(token.length);
    return JSON.parse(originalData); // Convert back to original object
  } else {
    throw new Error('Invalid token or corrupted data');
  }
}
