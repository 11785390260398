import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortName',
    standalone: true
})
export class ShortNamePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (!value) return '';
        let short = value.split(' ').map((n) => n[0]).join('');
        if (short.length > 2) {
            short = short.substring(0, 2);
        }
        return short.toUpperCase();
    }
}
