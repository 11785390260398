import { Injectable } from '@angular/core';
import { BaseService } from 'app/core/services/base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { SIPdetailsResponse } from '../models/call-center.models';
import { StatusValueEnum } from 'app/core/enums/common.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CallCenterSharedService extends BaseService {

  public isSIPRegistered$=new BehaviorSubject<boolean>(false);

  public popupOpenStatus = new BehaviorSubject<boolean>(false);

  private _sipDetails: SIPdetailsResponse = new SIPdetailsResponse();

  public connection: any;

  public connectingSIP$=new BehaviorSubject<boolean>(false);

  public processingStatusChange$=new BehaviorSubject<boolean>(false);

  public processingStatusChangeMsg:string='Connecting server..';

  public _agentStatus: Subject<{status:any,updateStatus:boolean}> = new Subject<{status:any,updateStatus:boolean}>();

  public agentStatus$ = this._agentStatus.asObservable();

  public toBeStatus$=new BehaviorSubject<string>(StatusValueEnum.OFFLINE);

  public isInternetConnected$=new BehaviorSubject<boolean>(true);

  public selectedAudioInput: string;

  public selectedOutputAudio: string;

  public selectedVideoSource: any;

  public availableAudioInputDevices: any[] = [];

  public _callQ = new BehaviorSubject<any[]>([]);

  // public _callQ$ = this._callQ.asObservable()

  private autoAccepCallIds: any = [];

  public outboundParentIds: any = [];

  public showDialer = false

  public notificationForCall:boolean = false

  private isMicUsed

  //public notificationForCall: boolean = false

  public ringAudio = new Audio();

  public lastDialledNumber:string="";

  private _hideClosePanel = new BehaviorSubject<boolean>(false);

  public hideClosePanel$ = this._hideClosePanel.asObservable();

  public isCallConnected$ = new Subject<any>();

  public get isNotificationEnabled()
  {
    return Notification.permission == "granted"
  }
  public contactSaveTempData : any = null;

  public get activeCallerSession()
  {
    return this.acceptCallQ.filter(x => x.customValues?.notification_established == true &&   x.customValues?.isCallOnHold == false)[0]
  } 

  public get initialCallQ()
  {
    return this._callQ.getValue().filter(x => x.customValues?.notification_established == false)
  }

  public get initialCallQOutbound()
  {
    return this._callQ.getValue().filter(x => x.customValues?.notification_established == false && x.customValues.callType == 'outbound')
  }
  
  public get callQ()
  {
    return this._callQ.getValue()
  }

  public set callQ(session:any)
  {
    this._callQ.next(session)
    // console.warn("Before local storage", session)
    // localStorage.setItem("CallInfo", JSON.stringify(session))
    // console.warn("After local storage",localStorage.getItem("CallInfo"))
  }

  public get acceptCallQ()
  {
    return this._callQ.getValue().filter(x => x.customValues?.notification_established == true)
  }

  public get acceptCallQOutbound()
  {
    return this._callQ.getValue().filter(x => x.customValues?.notification_established == true && x.customValues.callType == 'outbound')
  }

  public get holdCallQ()
  {
    return this._callQ.getValue().filter(x => x?.customValues.isCallOnHold == true)
  }

  public set sipDetails(sipDet:SIPdetailsResponse)
  {
    this._sipDetails = sipDet
  }

  public get sipDetails()
  {
    return this._sipDetails
  }

  constructor(private toastr:ToastrService){
    super()
  }

checkCallTimeout(session:any)
  {
    if(!session.checkouttimer)
      {
        session.checkouttimer = setInterval(() =>{

          session.customValues.checkoutTime++

          if(session.customValues?.timeout > 0)
            {
              if( session.customValues?.checkoutTime >= session.customValues?.timeout)  
                {
                  if(session.customValues.notification_established == false)
                    {
                        this.stopCheckoutTimer(session)
                        this.removeFromCallQ(session.callDetails.call_id)  //clear callsession if not established                  
                    }
                }
            }
            else
            {
              this.stopCheckoutTimer(session)
            }
   

        },1000)
      }
  }

  stopCheckoutTimer(session: any) {

    if (session.checkouttimer) {

      clearInterval(session.checkouttimer);
      session.checkouttimer = null;

    }
  }

  startCallTimer(session: any) {
    if (!session.timer) {
      session.timer = setInterval(() => {
        session.customValues.elapsedTime++;
      }, 1000);
    }
  }

  stopCallTimer(session: any) {
    if (session.timer) {
      clearInterval(session.timer);
      session.timer = null;
    }
  }

  setAutoAcceptableCallIds(id: string) {

    this.autoAccepCallIds.push({ call_id: id })

  }
  public removeFromCallQ(callid:string)
  {
    const index = this.callQ.findIndex(x => x.callDetails.call_id == callid)
    if(index != -1)
      {
        this.stopCallTimer(this.callQ[index])
        this.stopCheckoutTimer(this.callQ[index])
        var _callQ = this.callQ
        _callQ.splice(index, 1)
        const callQ = _callQ
        this.callQ = callQ
        this.checkQandCloseCallCenterPanel()
      }

      if (!this.activeCallerSession) {
          this.disconnectMic();
      }
      return index == -1 ? false : true 
  }

  public removeByParentCallId(parentCallId:string)
  {

    const index = this.callQ.findIndex(x => x.callDetails.parent_call_id == parentCallId)
    if(index != -1)
      {
        this.stopCallTimer(this.callQ[index])
        this.stopCheckoutTimer(this.callQ[index])
        var _callQ = this.callQ
        _callQ.splice(index, 1)
        const callQ = _callQ
        this.callQ = callQ
        this.checkQandCloseCallCenterPanel()
      }
      if (!this.callQ?.length) {
        //this.disconnect();
      }

      return index == -1 ? false : true 

  }


  public clearCompletedCallsfromCallQ(endedWhileOnHold: boolean = false) {

    var _callQ = this.callQ
    _callQ.forEach((callerSession, index) => {

      if (callerSession.session.state == "Terminated" && callerSession.customValues.isCallOnHold == false) {
        _callQ.splice(index, 1)
      }

    })
    this.callQ = _callQ;

    if (!this.callQ?.length) {
      if( this.showDialer == false)
        {
          this.popupOpenStatus.next(false)
        }
  
      //this.disconnect();
    }
  }
  updateCallQ(invitation: any,type: string) {

    let index
    if(type == 'inbound')
      {
         index = this.callQ.findIndex(x => x.callDetails.call_id == invitation.callDetails.call_id)
      }
     else if(type == 'outbound')
      {
         index = this.callQ.findIndex(x => x.callDetails.parent_call_id == invitation.callDetails.parent_call_id)
      } 

    if (index != -1) {
      var _callQ = this.callQ
      _callQ.splice(index, 1,invitation)
      this.callQ = _callQ;
    }
  }

  updateCallQWithPushid(callersession)
  {
    var index = this.callQ.findIndex(x => x.customValues.pushid ==callersession.customValues.pushid)
    if (index != -1) {
      var _callQ = this.callQ
      _callQ.splice(index, 1,callersession)
      this.callQ = _callQ;
    }

  }

  

  verifyCallIdAutoAccept(id: string) {
    
    if (this.autoAccepCallIds.filter(x => x.call_id == id)?.length) {
      return true
    }
    else {
      return false
    }
  }

muteCall(callerSession:any)
  {
    let peer = callerSession.session.sessionDescriptionHandler.peerConnection;
    let senders = peer.getSenders();
    if (!senders.length) return;
    senders.forEach(function (sender) {
      if (sender.track) sender.track.enabled = !callerSession.customValues.isMuted
    });
  }  

muteCallHold(callerSession:any,value:boolean)
{

  let peer = callerSession.session.sessionDescriptionHandler.peerConnection;
  let senders = peer.getSenders();
  if (!senders.length) return;
  senders.forEach(function (sender) {
    if (sender.track) sender.track.enabled = !value;
  });
  peer.getReceivers().forEach((receiver) => {
    if (receiver.track) receiver.track.enabled = !value;
  });
}

ring() {

    if (this.ringAudio) {
      this.ringAudio.pause();
    }
    if (!this.activeCallerSession) {
      this.ringSound();
    } else {
      this.ringAudio.pause();
    }
  }

stopRing() {

    this.ringAudio.pause()
  }

  checkQandCloseCallCenterPanel()
  {
    if(!this.initialCallQ?.length)
      {
        this.stopRing()
      }
    if(!this.callQ?.length)
      {
        if(this.contactSaveTempData == null)
        {
          this.popupOpenStatus.next(false)
        }
       
      }  
  }

  ringSound() {

    this.ringAudio.src = "../../../../assets/ringtone/telephone-ringwav-14674.mp3"
    this.ringAudio.muted = false;
    this.ringAudio.loop = true;
    this.ringAudio.play();

  }

  async disconnect() {

    if (this.connection) {
      this.connection.stop()
        .then(() => {
          console.log('SIP.js UserAgent stopped');
        })
        .catch(error => {
          console.error('Error stopping SIP.js UserAgent', error);
        });
      if(this.isMicUsed)
        {
          console.warn('mic offed')
          this.handleMic(false)
          this.isMicUsed = false
        }    
    }

  }

  async disconnectMic() {

    if(this.isMicUsed)
       {
         console.warn('mic offed')
         this.handleMic(false)
         this.isMicUsed = false
       }
  }

  handleMic(isOn: boolean): void {

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        stream.getAudioTracks().forEach((track) => {
          track.enabled = isOn;
        });

        // If turning off the mic, stop all tracks to release resources
        if (!isOn) {
          stream.getTracks().forEach(track => track.stop());
        }
        else
        {
          console.warn('mic on')
        }
      })
      .catch((error) => {
        this.toastr.error('Failed to access microphone:', error);
        alert('Microphone access is required. Please check your permissions.');
      });
  }

  async requestMicrophonePermission(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop()); // Immediately stop the tracks to release the mic
      this.isMicUsed = true
      return true;
    } catch (error) {
      this.toastr.error('To make and receive calls, you need to have the microphone permission enabled in your browser.','Mic permission is not enabled');
      return false;
    }
  }

  updateCallInfoInLocalStorage(session, is_remove = false){
    let CallInfo = JSON.parse(localStorage.getItem("CallInfo"))
    let index = CallInfo.findIndex((call)=> call.callDetails.call_id === session.callDetails.call_id)
    if (index != -1) {
      if(!is_remove)
        CallInfo.splice(index, 1,session)
      else
        CallInfo.splice(index, 1)
      localStorage.setItem("CallInfo", JSON.stringify(CallInfo))
    }
  }

  removeCurrentCallsfromCallQ(call_id){
    let index = this.callQ.findIndex((call)=> call.callDetails.call_id === call_id)
    if (index != -1) {
      this.callQ.splice(index, 1)
    }
  }

  changePanelClosebtnVisible(val:boolean)
  {
    this._hideClosePanel.next(val)
  }
}


